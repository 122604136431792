exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-automacao-robotica-de-processos-index-tsx": () => import("./../../../src/pages/automacao-robotica-de-processos/index.tsx" /* webpackChunkName: "component---src-pages-automacao-robotica-de-processos-index-tsx" */),
  "component---src-pages-compliance-index-tsx": () => import("./../../../src/pages/compliance/index.tsx" /* webpackChunkName: "component---src-pages-compliance-index-tsx" */),
  "component---src-pages-controladoria-apuracao-de-custos-index-tsx": () => import("./../../../src/pages/controladoria/apuracao-de-custos/index.tsx" /* webpackChunkName: "component---src-pages-controladoria-apuracao-de-custos-index-tsx" */),
  "component---src-pages-controladoria-estudos-de-viabilidade-index-tsx": () => import("./../../../src/pages/controladoria/estudos-de-viabilidade/index.tsx" /* webpackChunkName: "component---src-pages-controladoria-estudos-de-viabilidade-index-tsx" */),
  "component---src-pages-controladoria-formacao-de-precos-index-tsx": () => import("./../../../src/pages/controladoria/formacao-de-precos/index.tsx" /* webpackChunkName: "component---src-pages-controladoria-formacao-de-precos-index-tsx" */),
  "component---src-pages-controladoria-gestao-por-indicadores-kpis-index-tsx": () => import("./../../../src/pages/controladoria/gestao-por-indicadores-kpis/index.tsx" /* webpackChunkName: "component---src-pages-controladoria-gestao-por-indicadores-kpis-index-tsx" */),
  "component---src-pages-controladoria-index-tsx": () => import("./../../../src/pages/controladoria/index.tsx" /* webpackChunkName: "component---src-pages-controladoria-index-tsx" */),
  "component---src-pages-gestao-por-processos-de-negocio-bpm-index-tsx": () => import("./../../../src/pages/gestao-por-processos-de-negocio-bpm/index.tsx" /* webpackChunkName: "component---src-pages-gestao-por-processos-de-negocio-bpm-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-okr-objetivos-e-resultados-chaves-index-tsx": () => import("./../../../src/pages/okr-objetivos-e-resultados-chaves/index.tsx" /* webpackChunkName: "component---src-pages-okr-objetivos-e-resultados-chaves-index-tsx" */),
  "component---src-pages-politica-de-privacidade-index-tsx": () => import("./../../../src/pages/politica-de-privacidade/index.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-index-tsx" */),
  "component---src-pages-quem-somos-index-tsx": () => import("./../../../src/pages/quem-somos/index.tsx" /* webpackChunkName: "component---src-pages-quem-somos-index-tsx" */),
  "component---src-pages-simulador-viabilidade-rpa-index-tsx": () => import("./../../../src/pages/simulador-viabilidade-rpa/index.tsx" /* webpackChunkName: "component---src-pages-simulador-viabilidade-rpa-index-tsx" */),
  "component---src-pages-sistema-de-gestao-integrado-index-tsx": () => import("./../../../src/pages/sistema-de-gestao-integrado/index.tsx" /* webpackChunkName: "component---src-pages-sistema-de-gestao-integrado-index-tsx" */)
}

