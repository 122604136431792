import { observable } from 'mobx'

export const LoadingContext = observable({
  loading: true,
  setLoading: () => {
    LoadingContext.loading = true
  },
  disabledLoading: () => {
    LoadingContext.loading = false
  },
})
