const loading = require('./src/utils/LoadingContext')

exports.onInitialClientRender = () => {
  setTimeout(() => {
    loading.LoadingContext.disabledLoading()
  }, 2000)
}

exports.onRouteUpdateDelayed = () => {
  loading.LoadingContext.disabledLoading()
}
